import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useJsonToCsv } from 'react-json-csv';
import {
  CloudDownloadOutlined,
  DownSquareOutlined,
  SyncOutlined,
  ClockCircleTwoTone,
  BellTwoTone,
  RocketTwoTone,
  StopTwoTone,
  CheckSquareTwoTone,
  PhoneOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  FlagOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import {
  Input,
  Avatar,
  notification,
  Table,
  Typography,
  Descriptions,
  Dropdown,
  Menu,
  Button,
  Space,
  Tooltip,
  Popover,
  Tabs,
  Switch,
  Select,
  Row,
  Col,
  Image,
  Tag,
  Checkbox,
  Popconfirm,
  DatePicker
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  businessAtom,
  orderEditAtom,
  orderItemsAtom,
  ordersAtom,
  pageAtom,
  reloadOrder,
  rowsAtom,
  selectedOrderAtom,
  timezoneAtom,
  profileAtom,
} from '../../../../atoms/Atoms';
import {
  renderDate,
  formatPhoneNumber,
  mapPaymentType,
  mapOrderType,
  mapOrderStatus,
  thousand,
  convertTaxRule,
  renderShortDate,
  renderHour,
} from '../../../utils/functions';
import { paymentOptionsSelector } from '../../../../atoms/Selectors';
import OrderActions from '../../../shared-components/drawers/OrderActions';
import api from '../../../../api/api';
import Panel from '../../../shared-components/panel/Panel';
import Grocefy from '../../../../assets/images/grocefyLogoAlone.png';
import { dateRangePresets } from '../../../utils/utils';

function DeliveriesSearch() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const store = useRecoilValue(businessAtom);
  const profile = useRecoilValueLoadable(profileAtom);
  const [currentPage, setCurrentPage] = useState(0);
  const [size, setSize] = useState(20);
  const [current, setCurrent] = useRecoilState(pageAtom);
  const [rows, setRows] = useRecoilState(rowsAtom);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const timezone = useRecoilValue(timezoneAtom);
  const [selectedZone, setSelectedZone] = useState(null);
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [loadingDeliveryZones, setLoadingDeliveryZones] = useState(false);
  const [filter, setFilter] = useState('ALL');
  const [filterName, setFilterName] = useState('All');
  const [orderType, setOrderType] = useState(0);
  const [orderTypeName, setOrderTypeName] = useState('Pending');
  const [loadingItems, setLoadingItems] = useState(false);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [searchTerm, setSearchTerm] = useState('');
  const paymentOptions = useRecoilValueLoadable(paymentOptionsSelector);
  const [selectedOrder, setSelectedOrder] = useRecoilState(selectedOrderAtom);
  const [editOrder, setEditOrder] = useRecoilState(orderEditAtom);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reload, setReload] = useRecoilState(reloadOrder);
  const { saveAsCsv } = useJsonToCsv();
  const [itemCount, setItemCount] = useState(0);
  const [orderDesc, setOrderDesc] = useState(false);
  const [included, setIncluded] = useState(0);
  const [order, setOrder] = useRecoilState(ordersAtom);
  const [orderHeader, setOrderHeader] = useState(null);
  const [loadingOrderHeader, setLoadingOrderHeader] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [totalItemsInSearch, setTotalItemsInSearch] = useState(0);
  const [dates, setDates] = useState({});

  const listFields = {
    confirmationCode: 'Order #',
    customer: 'Customer',
    pickUpName: 'Pickup Customer',
    phone: 'Customer Phone',
    statusText: 'Order Status',
    specialInstruction: 'Order Instructions',
    deliveryZoneName: 'Zone',
    driverName: 'Driver',
    customerLocationAddress: 'Delivery Address',
    customerLocationCity: 'Delivery City',
    customerLocationZipcode: 'Delivery Zipcode',
    customerLocationInstructions: 'Delivery Instructions',
    customerLocationLat: 'Lat',
    customerLocationLon: 'Lon',
    desiredDate: 'Desired Date',
    desiredTime: 'Desired Time',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    total: 'Order Total',
  };
  const orderTypes = [
    { name: t('all'), type: 12 },
    { name: t('pending'), type: 0 },
    { name: t('status_delivery_assigned'), type: 22 },
    { name: t('status_delivery_started'), type: 20 },
    { name: t('dispatched'), type: 10 },
    { name: t('status_delivered'), type: 10 },
    { name: t('cancelled'), type: 11 },
  ];
  const filters = [
    { name: t('all'), filter: '' },
    { name: t('today'), filter: 'TODAY' },
    { name: t('tomorrow'), filter: 'TOMORROW' },
    { name: t('future'), filter: 'FUTURE' },
  ];
  const history = useHistory();

  if (!store) {
    history.push('/');
    return null;
  }

  if (store && !store.allowDelivery) {
    history.push('/');
    return null;
  }

  function showMessage(message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  }

  function getProviders() {
    setLoadingProviders(true);
    api.get(`tpd/providers/${profile?.contents?.id}`)
      .then((response) => {
        setLoadingProviders(false);
        if (response.data.success) {
          setProviders(response.data.data.results);
          setSelectedProvider(response.data.data.results[0]);
        } else {
          console.error(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingProviders(false);
        console.error(error);
      });
  }

  function mapData(data) {
    return _.map(data, (p, index) => ({
      key: index,
      id: p.id,
      type: t(mapOrderType(p.type)),
      typeEnum: p.type,
      confirmationCode: p.confirmationCode,
      customer: p.customerName,
      customerId: p.customerId,
      businessName: p.businessName,
      total: p.orderTotal,
      bags: p.bags,
      subTotal: thousand(p.productsTotal),
      munTax: thousand(p.municipalTax),
      stateTax: thousand(p.stateTax),
      deliveryFee: thousand(p.deliveryTotal),
      pickupFee: thousand(p.processingFee),
      phone: formatPhoneNumber(p.customerPhone),
      ivuCN: p.ivuControlNumber,
      itemCount: p.itemCount,
      cardNickname: p.cardNickname,
      nickName: p.nickName,
      status: p.status,
      statusText: t(mapOrderStatus(p.status)),
      startSlotTime: p.startSlotTime,
      endSlotTime: p.endSlotTime,
      specialInstruction: p.specialInstruction ? p.specialInstruction.replace(/,/g, '') : '',
      paymentTypeText: t(mapPaymentType(p.paymentType)),
      paymentType: p.paymentType,
      dispatchDateTime: renderDate(p.dispatchDateTime, timezone),
      createdAt: renderDate(p.createdAt, timezone),
      desiredDateTime: renderDate(p.desiredDateTime, timezone),
      desiredDate: renderShortDate(p.desiredDateTime, timezone),
      desiredTime: renderHour(p.desiredDateTime, timezone),
      deliveryDate: renderShortDate(p.dispatchDateTime, timezone),
      deliveryTime: renderHour(p.dispatchDateTime, timezone),
      transferTotal: p.transferTotal,
      processingFee: p.processingFee,
      transferCompleted: p.transferCompleted ? 'Yes' : 'No',
      authTotal: p.authTotal.toFixed(2),
      returnTotal: p.returnTotal.toFixed(2),
      credit:
        p.authTotal - p.orderTotal
          ? (p.authTotal - p.orderTotal).toFixed(2)
          : '0.00',
      referenceNumber:
        p.referenceNumber || p.referenceNumber !== ''
          ? p.referenceNumber
          : 'n/a',
      refNumAvailable: p.referenceNumber !== '' && p.referenceNumber !== null,
      discountAmount: p.discountAmount,
      businessDescription: p.businessDescription,
      returnNeeded: p.returnNeeded ? 'Yes' : 'No',
      returnCompleted: p.returnCompleted ? 'Yes' : 'No',
      athReturnError: p.athReturnError ? 'Yes' : 'No',
      wasAlreadyCharged: p.hasBeenCharged ? 'Yes' : 'No',
      wasAlreadyAuthed: p.hasBeenAuthed ? 'Yes' : 'No',
      brand: p.brand,
      cardNumber: p.cardNumber,
      address: p.customerLocation.addressLine,
      city: p.customerLocation.city,
      cardholderName: p.cardholderName,
      customerLocationName: p.customerLocationName,
      customerLocationId: p.customerLocationId,
      deliveryZoneId: p.deliveryZoneId,
      deliveryZoneName: p.deliveryZoneName,
      driverName: p.driverName ? p.driverName : '',
      driverPic: p.driverPic,
      driverPhone: p.driverPhone,
      deliveryTotal: p.deliveryTotal,
      items: p.items,
      driverInstructions: p.driverInstructions,
      customerLocationLat: p.customerLocation?.geo.lat,
      customerLocationLon: p.customerLocation?.geo.lon,
      customerLocationAddress: p.customerLocation?.addressLine?.replace(
        /,/g,
        ''
      ),
      customerLocationCity: p.customerLocation?.city,
      customerLocationZipcode: p.customerLocation?.zipcode,
      customerLocationInstructions: p.customerLocation?.instructions?.replace(
        /,/g,
        ''
      ),
      signatureImageUrl: p.signatureImageUrl,
      ebtReceiptUrl: p.ebtReceiptUrl,
      pickUpName: p.pickUpName,
    }));
  }

  function getDelvieryZones() {
    setLoadingDeliveryZones(true);
    let url = `deliveries/deliveryzones/active/${store?.locations[0]?.id}`;
    if (selectedProvider?.id !== null) {
      url += `/${selectedProvider?.id}`;
    }
    api
      .get(url)
      .then((response) => {
        setDeliveryZones(response.data.data.results);
        setLoadingDeliveryZones(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDeliveryZones(false);
      });
  }

  function getOrderHeader() {
    const data = {
      filterString: 'DELIVERY',
      StartDate: moment(dates.start).utc(),
      EndDate: moment(dates.end).utc().add(1, 'days'),
    };
    if (selectedZone) {
      data.filterString += ` DZONE-${selectedZone.id}`;
    }
    if (filter?.length > 0) {
      data.filterString += ` ${filter}`;
    }
    setLoadingOrderHeader(true);
    api
      .post(`businesses/orderheader/${store?.id}`, data)
      .then((response) => {
        setOrderHeader(response.data.data);
        setLoadingOrderHeader(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingOrderHeader(false);
      });
  }

  function searchOrders(value, page) {
    getOrderHeader();
    const url = store
      ? `businesses/V2/${store.id}/orders/fast/${size}/${page}/${orderType}`
      : 'orders/search';
    const data = {
      SearchString: `${value}`,
      filterString: 'DELIVERY',
      OrderAsc: !orderDesc ? true : null,
      OrderDesc: orderDesc ? true : null,
      StartDate: moment(dates.start).utc(),
      EndDate: moment(dates.end).utc().add(1, 'days'),
    };
    if (selectedProvider?.id != null) {
      data.filterString += ` DPROVIDER-${selectedProvider.id}`;
    }
    if (selectedZone) {
      data.filterString += ` DZONE-${selectedZone.id}`;
    }
    if (filter?.length > 0) {
      data.filterString += ` ${filter}`;
    }
    setLoading(true);
    api
      .post(url, data)
      .then((response) => {
        if (response?.data?.message?.length > 0) {
          setTotalItemsInSearch(parseInt(response?.data?.message, 10));
        } else {
          setTotalItemsInSearch(0);
        }
        const mapped = mapData(response.data.data.results);
        setOrders(mapped);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  function getAllOrders() {
    const url = store
      ? `businesses/report/${store.id}/orders/${orderType}`
      : 'orders/search';
    const data = {
      SearchString: `${searchTerm}`,
      filterString: 'DELIVERY',
      OrderAsc: !orderDesc ? true : null,
      OrderDesc: orderDesc ? true : null,
      All: true,
      StartDate: moment(dates.start).utc(),
      EndDate: moment(dates.end).utc().add(1, 'days'),
    };
    if (selectedProvider?.id != null) {
      data.filterString += ` DPROVIDER-${selectedProvider.id}`;
    }
    if (selectedZone) {
      data.filterString += ` DZONE-${selectedZone.id}`;
    }
    if (filter?.length > 0) {
      data.filterString += ` ${filter}`;
    }
    setLoading(true);
    api
      .post(url, data, { responseType: 'blob' })
      .then((response) => {
        setLoading(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `deliveries_${new Date().toLocaleDateString()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  const onTableChange = (page, pageSize) => {
    if (page - 1 !== currentPage) {
      setCurrentPage(page - 1);
      searchOrders(searchTerm, page - 1);
    } else {
      setSize(pageSize);
    }
  };

  function mapOrderItems(data) {
    const sorted = _.map(data, (p) => ({
      key: p.id,
      id: p.id,
      completeName: p.completeName,
      name: p.name,
      brand: p.brand,
      amount: p.amount,
      upc: p.upc,
      aisle: p.aisle,
      price: p.price,
      description: p.description,
      extendedPrice: p.extendedPrice,
      itemImage: p.itemImage,
      hasRandomWeight: p.hasRandomWeight,
      sectionName: p.sectionName,
      isAlcoholicBeverage: p.isAlcoholicBeverage,
      isPacked: p.isPacked,
      isPerishable: p.isPerishable,
      isVoided: p.isVoided,
      manuallyAdded: p.manuallyAdded,
      acceptsSubstitutes: p.acceptsSubstitutes,
      taxRule: t(convertTaxRule(p.taxRule)),
    }));
    return _.sortBy(sorted, [(o) => o.isVoided]);
  }

  function loadItems(record) {
    api
      .get(`orders/${record.id}/items/all`)
      .then((response) => {
        const map = mapOrderItems(response.data.data.results);
        setOrderItems(map);
        setLoadingItems(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function load(record) {
    loadItems(record);
  }

  const onSearch = async (value) => {
    setSearchTerm(value);
    setCurrentPage(0);
    searchOrders(value, 0);
    // getOrderHeader();
  };

  useEffect(() => {
    setDates({
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('week').format('YYYY-MM-DD')
    });
    getProviders();
  }, []);

  useEffect(() => {
    if (store && selectedProvider) {
      getDelvieryZones();
      setCurrentPage(0);
      searchOrders(searchTerm, 0);
      // getOrderHeader();
    }
  }, [store, selectedProvider]);

  useEffect(() => {
    if (store) {
      setCurrentPage(0);
      searchOrders(searchTerm, 0);
      // getOrderHeader();
    }
  }, [filter, selectedZone, orderType, reload, orderDesc, store, dates]);

  return (
    <div className="mx-4">
      <div className="mb-6">
        <Panel>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 py-4">
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                <BellTwoTone
                  twoToneColor="#eb2f96"
                  className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                />
              </div>
              <div className="flex flex-col text-4xl mt-2 justify-center p-2 text-xs text-gray-800">
                <div>
                  {orderHeader && orderHeader.pendingOrders}
                </div>
              </div>
              <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                <div>
                  {t('pending')}
                </div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                <ClockCircleTwoTone
                  className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                />
              </div>
              <div className="flex flex-col text-4xl mt-2 justify-center p-2 text-xs text-gray-800">
                <div>
                  {orderHeader && orderHeader.waitingOrders}
                </div>
              </div>
              <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                <div>
                  {t('assigned')}
                </div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                <RocketTwoTone
                  twoToneColor="#A117F2"
                  className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                />
              </div>
              <div className="flex flex-col text-4xl mt-2 justify-center p-2 text-xs text-gray-800">
                <div>
                  {orderHeader && orderHeader.inTransitOrders}
                </div>
              </div>
              <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                <div>
                  {t('in_transit')}
                </div>
              </div>
            </div>
            <div className="hidden shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                <StopTwoTone
                  twoToneColor="#FF0000"
                  className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                />
              </div>
              <div className="flex flex-col text-4xl mt-2 justify-center p-2 text-xs text-gray-800">
                <div>
                  {orderHeader && orderHeader.cancelledOrders}
                </div>
              </div>
              <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                <div>
                  {t('cancelled')}
                </div>
              </div>
            </div>
            <div className="shadow rounded-xl flex h-20">
              <div className="flex flex-shrink-0 items-center justify-center text-white text-3xl bg-rose-500 w-1/4 rounded-l-xl">
                <CheckSquareTwoTone
                  twoToneColor="#52c41a"
                  className="-ml-1 flex-shrink-0 self-center h-5 w-5"
                />
              </div>
              <div className="flex flex-col text-4xl mt-2 justify-center p-2 text-xs text-gray-800">
                <div>
                  {orderHeader && orderHeader.completedOrders}
                </div>
              </div>
              <div className="flex flex-col justify-end p-4 text-lg text-gray-800 font-semibold">
                <div>
                  {t('completed')}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <Row>
        <Col>
          <div className="flex flex-row" style={{ float: 'right' }}>
            <Popconfirm
              disabled={loading}
              title={t('export_all_orders_warning')}
              description={t('export_all_orders_warning_desc')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => getAllOrders()}
            >
              <Button
                loading={loading}
                className="mr-4"
                size="small"
                type="primary"
                style={{ float: 'left' }}
                icon={<CloudDownloadOutlined />}
              >
                {t('export_all_orders')}
              </Button>
            </Popconfirm>
            <Button
              className="mb-4"
              type="primary"
              size="small"
              icon={<SyncOutlined spin={loading} />}
              onClick={() => {
                setReload(!reload);
              }}
            >
              {t('reload')}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="flex flex-column">
        <Input.Search
          className="mr-4"
          placeholder="i.e. Juan del Pueblo or AE123D3E"
          allowClear
          enterButton={t('search')}
          loading={loading}
          size="medium"
          onSearch={onSearch}
        />
        <div className="mr-4 flex">
          <span className="my-auto mr-1">{t('descending')}</span>
          <Checkbox
            checked={orderDesc}
            onChange={(e) => {
              setOrderDesc(e.target.checked);
              // console.log(selectedProvider?.id ? 'true' : 'false');
            }}
          />
        </div>
        <Select
          // hidden={providers.length < 2}
          value={selectedProvider?.id ? selectedProvider.id : null}
          style={{ width: 120 }}
          className="mr-4"
          loading={loadingProviders}
          onChange={(obj) => {
            if (obj) {
              const prov = _.filter(providers, (b) => b.id === obj);
              setSelectedProvider(prov[0]);
            } else {
              setSelectedProvider({ id: null });
            }
          }}
        >
          <Option
            value={null}
            key={null}
          >
            {t('any')}
          </Option>
          {_.map(providers, (b) => (
            <Option
              value={b.id}
              key={b.id}
            >
              {b.name}
            </Option>
          ))}
        </Select>
        <Dropdown
          trigger="click"
          className="mr-5"
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  setSelectedZone(null);
                }}
              >
                {t('any')}
              </Menu.Item>
              {_.map(deliveryZones, (zone) => (
                <Menu.Item
                  onClick={() => {
                    setSelectedZone(zone);
                  }}
                >
                  {zone.name}
                </Menu.Item>
              ))}
            </Menu>
          }
          arrow="true"
        >
          <Button loading={loadingDeliveryZones}>
            {t('zone')}: {selectedZone ? selectedZone?.name : 'Any'}
            <DownSquareOutlined />
          </Button>
        </Dropdown>
        <Dropdown
          trigger="click"
          className="mr-5"
          overlay={
            <Menu>
              {_.map(orderTypes, (o) => (
                <Menu.Item
                  onClick={() => {
                    setOrderType(o.type);
                    setOrderTypeName(o.name);
                  }}
                >
                  {o.name}
                </Menu.Item>
              ))}
            </Menu>
          }
          arrow="true"
        >
          <Button>
            {t('type')}: {orderTypeName}
            <DownSquareOutlined />
          </Button>
        </Dropdown>
        {/* <Dropdown
          trigger="click"
          overlay={
            <Menu>
              {_.map(filters, (f) => (
                <Menu.Item
                  onClick={() => {
                    setFilter(f.filter);
                    setFilterName(f.name);
                  }}
                >
                  {f.name}
                </Menu.Item>
              ))}
            </Menu>
          }
          arrow="true"
        >
          <Button>
            {t('filter')}: {filterName !== null ? filterName : filter}
            <DownSquareOutlined />
          </Button>
        </Dropdown> */}
        <RangePicker
          format="YYYY-MM-DD"
          value={[
            dayjs(dates?.start),
            dayjs(dates?.end)
          ]}
          presets={dateRangePresets(t)}
          onChange={(date, dateString) => {
            setDates({
              start: dateString[0],
              end: dateString[1]
            });
          }}
        />
      </div>
      <br />
      <Table
        size="small"
        loading={loading}
        bordered
        scroll={{ x: 500 }}
        pagination={{
          pageSize: size,
          showSizeChanger: true,
          defaultCurrent: 0,
          current: currentPage + 1,
          total: totalItemsInSearch,
          onChange: onTableChange
        }}
        columns={[
          // {
          //   title: t('code'),
          //   dataIndex: 'confirmationCode',
          //   key: 'confirmationCode',
          //   align: 'center',
          //   width: 120,
          //   className: 'text-xs',
          //   render: (text) => (
          //     <Typography.Text copyable>{text}</Typography.Text>
          //   ),
          // },
          {
            title: t('zone'),
            dataIndex: 'deliveryZoneName',
            key: 'zone',
            align: 'left',
            className: 'text-xs',
            render: (text) => <Typography.Text>{text}</Typography.Text>,
          },
          {
            title: t('city'),
            dataIndex: 'city',
            key: 'city',
            align: 'left',
            className: 'text-xs',
            render: (text) => <Typography.Text>{text}</Typography.Text>,
          },
          {
            key: 'details',
            align: 'left',
            className: 'text-xs',
            render: (text) =>
              <div>
                <span>{text.customer}</span>
                <br />
                <span>{text.confirmationCode}</span>
              </div>,
          },
          {
            title: t('driver'),
            dataIndex: 'driverName',
            key: 'driver',
            align: 'left',
            className: 'text-xs',
            render: (text) => {
              if (!text) {
                return (
                  <Tag
                    color="processing"
                    size="small"
                    className="mr-0"
                  >
                    {t('pending')}
                  </Tag>
                );
              }
              return (
                <>
                  <Tag
                    color="success"
                    size="small"
                    className="mr-0"
                  >
                    {text}
                  </Tag>
                </>
              );
            }
          },
          {
            title: t('total'),
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            className: 'text-xs',
            render: (text) => <span>${text ? text.toFixed(2) : '0.00'}</span>,
            sorter: (a, b) => a.total - b.total,
          },
          {
            title: t('status'),
            key: 'status',
            align: 'left',
            className: 'text-xs',
            render: (text) => {
              if (text.status === 0) {
                return (
                  <Tag
                    icon={<SyncOutlined spin />}
                    color="processing"
                    size="small"
                    className="mr-0"
                  >
                    {t('processing')}
                  </Tag>
                );
              }
              if (text.status === 2) {
                return (
                  <Tag
                    icon={<ClockCircleOutlined />}
                    color="success"
                    size="small"
                    className="mr-0"
                  >
                    {t(mapOrderStatus(text.status))}
                  </Tag>
                );
              }
              if (text.status === 5) {
                return (
                  <Tag
                    icon={<SyncOutlined spin />}
                    color="success"
                    size="small"
                    className="mr-0"
                  >
                    {t(mapOrderStatus(text.status))}
                  </Tag>
                );
              }
              if (text.status === 7) {
                return (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="success"
                    size="small"
                    className="mr-0"
                  >
                    {t(mapOrderStatus(text.status))}
                  </Tag>
                );
              }
              if (text.status === 8 || text.status === 9) {
                return (
                  <Tag
                    icon={<FlagOutlined />}
                    color="error"
                    size="small"
                    className="mr-0"
                  >
                    {t(mapOrderStatus(text.status))}
                  </Tag>
                );
              }

              return (
                <>
                  <Tag
                    icon={<FlagOutlined />}
                    color="processing"
                    size="small"
                    className="mr-0"
                  >
                    {t(mapOrderStatus(text.status))}
                  </Tag>
                </>
              );
            },
          },
          {
            title: t('date'),
            key: 'desiredDate',
            align: 'center',
            className: 'text-xs',
            width: 80,
            render: (text) => <span>{text.deliveryDate}</span>,
          },
          {
            title: t('expected'),
            key: 'desiredtTime',
            align: 'center',
            className: 'text-xs',
            width: 100,
            render: (text) =>
              <span>{`${moment(`${text.startSlotTime}+0000`)
                .tz(timezone)
                .format('h a')}-${moment(`${text.endSlotTime}+0000`)
                .tz(timezone)
                .format('h a')}`}
              </span>,
          },
          {
            title: t('completed'),
            key: 'dispatchDateTime',
            align: 'center',
            className: 'text-xs',
            width: 80,
            render: (text) => <span>{text.dispatchDateTime ? text.dispatchDateTime : '-'}</span>,
          },
          {
            title: `${t('bags')} | ${t('items')}`,
            align: 'center',
            className: 'text-xs',
            render: (text, row) => (
              <Space>
                <Popover
                  title={t('items')}
                  placement="left"
                  trigger="click"
                  width="100%"
                  content={
                    <div>
                      {/* {orderItems && itemCount ? isIncluded() : null } */}
                      <Typography.Text>
                        {t('item_count')}: {itemCount}
                      </Typography.Text>
                      <br />
                      <br />
                      <Table
                        size="small"
                        bordered
                        loading={loadingItems}
                        columns={[
                          {
                            title: t('brand'),
                            dataIndex: 'brand',
                            key: 'brand',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('name'),
                            dataIndex: 'name',
                            key: 'name',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('description'),
                            dataIndex: 'description',
                            key: 'description',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: 'UPC',
                            dataIndex: 'upc',
                            key: 'upc',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text copyable>{info}</Typography.Text>
                            ),
                          },
                          {
                            title: t('amount'),
                            dataIndex: 'amount',
                            key: 'amount',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>
                                {info.toFixed(2)}
                              </Typography.Text>
                            ),
                          },
                          {
                            title: t('price'),
                            dataIndex: 'price',
                            key: 'price',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>
                                ${info.toFixed(2)}
                              </Typography.Text>
                            ),
                          },
                          {
                            title: t('included'),
                            dataIndex: 'isVoided',
                            key: 'isVoided',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Switch size="small" checked={!info} disabled />
                            ),
                          },
                          {
                            title: 'Tax Rule',
                            dataIndex: 'taxRule',
                            key: 'traxRule',
                            align: 'center',
                            className: 'text-xs',
                            render: (info) => (
                              <Typography.Text>{info}</Typography.Text>
                            ),
                          },
                        ]}
                        dataSource={
                          orderItems && orderItems.length > 0
                            ? orderItems
                            : null
                        }
                        expandable={{
                          expandRowByClick: false,
                          expandedRowRender: (record) => (
                            <div>
                              <Tabs>
                                <Tabs.TabPane key="1" tab={t('details')}>
                                  <Row className="flex">
                                    <Col span={12} className="flex">
                                      <Descriptions>
                                        <Descriptions.Item label={t('section_name')} />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Typography.Text>
                                              {record.sectionName}
                                            </Typography.Text>
                                          </Col>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={t('aisle')} />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Typography.Text>
                                              {record.aisle}
                                            </Typography.Text>
                                          </Col>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={12} className="flex">
                                      <Descriptions bordered>
                                        <Descriptions.Item label={t('item_image')} />
                                        <br />
                                        <Descriptions.Item>
                                          <Col span={24}>
                                            <Image
                                              alt="Item Image"
                                              width={150}
                                              src={record.itemImage}
                                            />
                                          </Col>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane key="2" tab="Flags">
                                  <Row className="flex">
                                    <Col span={12} className="flex">
                                      <Descriptions>
                                        <Descriptions.Item
                                          className="py-0"
                                          label={t('weighted')}
                                        >
                                          <Switch
                                            disabled
                                            size="small"
                                            checked={record.hasRandomWeight}
                                          />
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                    <Col span={10} offset={2}>
                                      <Descriptions>
                                        <Descriptions.Item label={t('alcoholic_beverages')}>
                                          <Switch
                                            disabled
                                            size="small"
                                            checked={record.isAlcoholicBeverage}
                                          />
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </Col>
                                  </Row>
                                </Tabs.TabPane>
                              </Tabs>
                            </div>
                          ),
                          rowExpandable: (record) => record,
                        }}
                      />
                    </div>
                  }
                >
                  <Button
                    disabled={row.favStoreId === 'Unknown'}
                    size="small"
                    className="w-14"
                    onClick={() => {
                      setIncluded(0);
                      setLoadingItems(true);
                      api
                        .get(`orders/${row.id}/items/all`)
                        .then((response) => {
                          setItemCount(response.data.data.results.length);
                          const map = mapOrderItems(response.data.data.results);
                          setOrderItems(map);
                          setLoadingItems(false);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    <ShoppingOutlined className="" /> {text.bags} | {text.itemCount}
                  </Button>
                </Popover>
              </Space>
            ),
          },
          {
            title: t('actions'),
            align: 'center',
            className: 'text-xs',
            render: (row) => (
              <Space>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setSelectedOrder(row);
                    loadItems(row);
                    setEditOrder(true);
                  }}
                >
                  {t('edit')}
                </Button>
              </Space>
            ),
          },
        ]}
        dataSource={orders}
        expandable={{
          expandRowByClick: false,
          expandedRowRender: (record) => (
            <Tabs
              onTabClick={() => {
                setSelectedRow(record.id);
                loadItems(record);
              }}
            >
              <Tabs.TabPane className="bg-white" key="0" tab="General">
                <div className="flex flex-columns justify-content bg-white">
                  <div className="p-6">
                    <Descriptions size="small" title={t('customer')}>
                      <Descriptions.Item>
                        <Typography.Text copyable>{record.customer}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item>
                        <PhoneOutlined className="mr-2" />
                        <Typography.Text copyable>{record.phone}</Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" title={t('pickup_name')}>
                      <Descriptions.Item>
                        <Typography.Text copyable>{record.pickUpName}</Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" title={t('dates')} className="mt-4">
                      <Descriptions.Item label={t('created')}>
                        <Typography.Text>{record.createdDiff}</Typography.Text>
                        <ClockCircleOutlined className="mr-2" />
                        <Typography.Text>{record.createdAt}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('completed')}>
                        <ClockCircleOutlined className="mr-2" />
                        <Typography.Text>{record.dispatchDateTime}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                    </Descriptions>
                  </div>
                  <div className="p-6">
                    <Descriptions size="small" title={t('payment')}>
                      <Descriptions.Item label={t('payment')}>
                        <Typography.Text>{record.paymentTypeText}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('card')}>
                        <Typography.Text>{record.nickName}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('card_holder')}>
                        <Typography.Text>
                          {record.cardholderName}
                        </Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('authorized')} className="hidden">
                        <Typography.Text>{`${record.wasAlreadyAuthed}`}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('charged')} className="hidden">
                        <Typography.Text>{`${record.wasAlreadyCharged}`}</Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="p-6">
                    <Descriptions Descriptions size="small" title={t('totals')}>
                      <Descriptions.Item label={t('subtotal')}>
                        <Typography.Text>${parseFloat(record.subTotal).toFixed(2)}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('municipal_tax')}>
                        <Typography.Text>${parseFloat(record.stateTax).toFixed(2)}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('state_tax')}>
                        <Typography.Text>${parseFloat(record.munTax).toFixed(2)}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('delivery_fee')}>
                        <Typography.Text>
                          ${parseFloat(record.deliveryFee).toFixed(2)}
                        </Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('processing_fee')}>
                        <Typography.Text>
                          ${parseFloat(record.processingFee).toFixed(2)}
                        </Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('total')}>
                        <Typography.Text>
                          ${parseFloat(record.total).toFixed(2)}
                        </Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                  <div className="p-6">
                    <Descriptions Descriptions size="small" title={t('other')}>
                      <Descriptions.Item className={record.orderType === 1 ? 'hidden' : ''} label={t('delivery_zone')}>
                        <Typography.Text>{record.deliveryZoneName}</Typography.Text>
                      </Descriptions.Item>
                      <br className={record.orderType === 1 ? 'hidden' : ''} />
                      <br className={record.orderType === 1 ? 'hidden' : ''} />
                      <Descriptions.Item label={t('ivu_control')}>
                        <Typography.Text>{record.ivuCN}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('(required/completed)')} className="hidden">
                        <Typography.Text>{`${record.returnNeeded}/${record.returnCompleted}`}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('discount')} className="hidden">
                        <Typography.Text>
                          ${record.discountAmount}
                        </Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('returned_amount')} className="hidden">
                        <Typography.Text>${record.returnTotal}</Typography.Text>
                      </Descriptions.Item>
                      <br />
                      <br />
                      <Descriptions.Item label={t('credit')} className="hidden">
                        <Typography.Text>${record.credit}</Typography.Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
              </Tabs.TabPane>
            </Tabs>
          ),
          rowExpandable: (record) => record,
        }}
      />
      {editOrder ? <OrderActions open={selectedOrder} /> : null}
    </div>
  );
}
export default DeliveriesSearch;
